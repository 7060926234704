<template>
    <div class="bio">
        <p>
            <i>"Miiesha holds multitudes – she is a movement" - Cyclone Wehner - NME</i>
            <br><br>
            The incredible voice and storytelling of Miiesha returns as soulful and heartbreaking as ever in her new single "Damaged". After a breakout 2020 Miiesha returns with her much anticipated follow up, produced by LUCIANBLOMKAMP (6lack), with vocal production by Simon Cohen (Justin Bieber).
            <br><br>
            <i>"Damaged is my most personal song yet, and the most painful for me. It is a song about the broken relationship I have with my mother, and how much I have wanted it to heal, and how much I have tried to fix it, only to find myself hurting more." - Miiesha</i>
            <br><br>
            From the small Aboriginal community of Woorabinda, Australia, Miiesha arrived on the RnB landscape with a sound and a story that instantly demanded attention.
            <br><br>
            A strong, proud Anangu/Torres Strait Islander woman, Miiesha (Pronounced My-ee-sha) released her debut collection of songs entitled Nyaaringu in 2020. Meaning 'what happened' in Pitjantjatjara language, Nyaaringu explores the stories and the strength Miiesha inherited from her late Grandmother, whose interludes narrate the project. Since its release, Nyaaringu has not only garnered critical acclaim, but received the 2020 ARIA for Best Soul/RnB Release, a QLD Music Award, a NIMA, and went on to top multiple Album of the Year lists.
            <br><br>
            Inspired by the sounds of RnB, Gospel and Soul, and the power of spoken word poetry, Miiesha is using her music to bring people together to help educate and inspire.
            <br><br>
            With a voice that showcases vulnerability and strength, often in the one breath, Miiesha sings of her people, her community and her story with the raw emotion of lived experience. She weaves together the personal and political so tightly, the listener begins to understand just how inseparable they are for a young Aboriginal woman.
            <br><br>
            Miiesha's story is not just worth hearing, it's needed.
        </p>
    </div>
</template>

<script>
export default {
    name: 'Bio'
}
</script>

<style>

    .bio {
        max-width: 900px;
        color: #ffffff;
        padding: 30px;
        text-align: left;
        display: flex;
        justify-self: center;
        align-items: center;
    }

    @media only screen and (max-width: 600px) {
        #app {
            background-position: 900px 0;
        }
    }
    
</style>